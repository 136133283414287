import { ReviewIdEnum } from '@app/core';
import { ProductIdNameService } from '@app/core/services/product-id-name.service';
import { State } from '@app/store/app.reducer';
import { createSelector } from '@ngrx/store';
import { ProductIdEnum } from 'outshared-lib';
import { ProductGradeKeyValue } from './product-grade-key-value.interface';

/**
 *  Product Grade selectors
 */
export const getProductGradeState = (state: State) => state.productGrade;

export const getProductGradeByProductId = (productId: ProductIdEnum) =>
    createSelector(getProductGradeState, (productGradeState) => {
        const reviewId = ProductIdNameService.getReviewIdByProductId(productId);
        return productGradeState.entities.find((item: ProductGradeKeyValue) =>
            item.key === reviewId,
        );
    });

export const getProductGradeByReviewId = (reviewId: ReviewIdEnum) =>
    createSelector(getProductGradeState, (productGradeState) => {
        return productGradeState.entities.find((item: ProductGradeKeyValue) =>
            item.key === reviewId,
        );
    });

export const shouldLoadProductGrade = (reviewId: ReviewIdEnum) =>
    createSelector(getProductGradeState, (productGradeState) => {
        const shouldLoad = !productGradeState.entities.some((item: ProductGradeKeyValue) => item.key === reviewId)
            && !productGradeState.loading.some((id: ReviewIdEnum) => id === reviewId);
        return shouldLoad ? reviewId : null;
    });
